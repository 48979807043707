let apiBase = "/api/v1/";

if (process.env.NODE_ENV === "development") {
  apiBase = "http://localhost:8080/api/v1/";
}

const addParam = (url, param, value = "") => {
  const first = url.indexOf("?") < 0;
  return `${url}${first ? "?" : "&"}${param}=${encodeURI(value)}`;
};

const api = {
  addParam,
  waitTime: {
    getAllByFacility: apiBase + "middleware/getWaitingRoom",
  },
};

export default api;
