import React, { useState } from "react";
import styled from "styled-components";
// import queryString from "query-string";

import "./App.css";
import Nav from "./components/Nav";
import Banner from "./components/Banner";
import Table from "./components/Table";
import theme from "./styles/theme";

const Div = styled.div`
  height: 50px;
  width: 1024px;
  margin: 0 auto;
  background-color: ${(p) => p.theme.color.backgroundPrimary};
  display: flex;
  align-items: center;
  @media ${(p) => p.theme.media.mobilePhone} {
    text-align: center;
    width: 100%;
  }
`;

const Title = styled.div`
  height: 27px;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  color: ${(p) => p.theme.color.textPrimary};
  flex: 1;
`;
function App({ ...props }) {
  // TODO: add company footer
  const [globalState, setGlobalState] = useState(null);

  const findQueryParams = window.location.search;

  // const findQueryParams = queryString.parse(window.location.search);
  // TODO: validate qery parms for therts, alert and redirect to a blocker page

  return (
    <div className="App">
      <Nav theme={theme} />
      <Banner theme={theme} globalState={globalState} />
      <Div theme={theme} role="info" aria-label="registration status">
        <Title theme={theme} aria-label="title">
          Registration Status Board
        </Title>
      </Div>
      <Table
        theme={theme}
        search={findQueryParams}
        setGlobalState={setGlobalState}
      />
    </div>
  );
}

export default App;
