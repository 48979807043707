import React, { useState, useEffect } from "react";
import styled from "styled-components";
import get from "lodash.get";
import queryString from "query-string";

//material design import: table
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// material design import: end here

import api from "../../utils/api.js";

const colDefs = [
  { header: "Name", field: "name", allign: "left" },
  { header: "Wait Times", field: "wTime", align: "center" },
  { header: "Status", field: "status", align: "center" },
  { header: "Reg Location", field: "regLocation", align: "center" },
];

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.color.backgroundLight};
  min-height: 200px;
  width: 940px;
  margin: 20px auto;
  box-shadow: 0 2px 4px 0 rgba(31, 37, 50, 0.2);

  @media ${(p) => p.theme.media.tablet} {
    // width: 85%;
    margin-bottom: 30px;
  }

  @media ${(p) => p.theme.media.mobilePhone} {
    width: 95%;
    margin-bottom: 30px;
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  tableCell: {
    color: "#1F2532",
  },
  highlightTableCell: {
    fontWeight: 600,
    color: "#26b86f",
  },
  tableHeader: {
    textTransform: "uppercase",
    fontWeight: 600,
  },
});

const tableTheme = createMuiTheme({
  typography: {
    fontFamily: "Open Sans",
  },
});

const apicall = (
  apiUri,
  search,
  parsedSearch,
  setData,
  setErrorMessage,
  setGlobalState
) => {
  // we will not make any api calls untill proper search terms are added to link
  if (!("unitnum" in parsedSearch) && !("contactguid" in parsedSearch)) {
    return setErrorMessage(
      "Something is not right, Please check the link provided to you. Bad request."
    );
  }

  fetch(`${apiUri.waitTime.getAllByFacility}${search}`)
    .then(async (response) => {
      const parsedData = await response.json();

      // check for error
      if (!response.ok) {
        const error = (parsedData && parsedData.message) || response.statusText;
        return Promise.reject(error);
      }

      // data shape check
      // body and active are required key
      const dataShapeError =
        "Internal error, please try again later. Insufficient response";
      if (!("body" in parsedData && "active" in parsedData.body)) {
        return Promise.reject(dataShapeError);
      }

      // check for expired link
      const expiredLink =
        "This page has expired. We will send you a new link during your next visit. Have a great day!";
      if (get(parsedSearch, "unitnum")) {
        const body = parsedData.body;
        setData(body);
        setGlobalState({
          header: get(body, "banner"),
          footer: get(body, "footer"),
        });
      } else {
        //check for active flag
        const isActive = get(parsedData, "body.active", undefined);
        if (isActive) {
          const body = parsedData.body;
          // set facility name and footer to globalState
          setGlobalState({
            header: get(body, "banner"),
            footer: get(body, "footer"),
          });
          return setData(body);
        }
        return Promise.reject(expiredLink);
      }
    })
    .catch((error) => {
      setErrorMessage(error);
    });
};

function DataTable({ theme, search, setGlobalState }) {
  const parsedSearch = queryString.parse(search);
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // hooks for react table
  const classes = useStyles();

  useEffect(() => {
    //we call api on every render
    apicall(
      api,
      search,
      parsedSearch,
      setData,
      setErrorMessage,
      setGlobalState
    );

    // reload each 30 seconds
    setInterval(() => {
      apicall(
        api,
        search,
        parsedSearch,
        setData,
        setErrorMessage,
        setGlobalState
      );
    }, 30000);
  }, []);

  // debug point
  // console.log(data, errorMessage);

  if (errorMessage) {
    return (
      <Wrapper theme={theme} role="error" aria-label="error  message">
        <p
          style={{
            color: "#1F2532",
            width: "80%",
            paddingTop: "10%",
            margin: "0px auto",
            wordWrap: "break-word",
          }}
        >
          {errorMessage}
        </p>
      </Wrapper>
    );
  }

  if (data === null) {
    return (
      <Wrapper theme={theme} role="info" aria-label="loading  message">
        <div
          style={{
            color: "#1F2532",
            width: "150px",
            paddingTop: "10%",
            margin: "0px auto",
            wordWrap: "break-word",
          }}
        >
          Loading...
        </div>
      </Wrapper>
    );
  }

  const headers = colDefs.map((c) => {
    return (
      <TableCell align={c.align} className={classes.tableHeader} key={c.header}>
        {c.header}
      </TableCell>
    );
  });

  const rows = data.table.map((row, i) => {
    // const enableHightlight = row.wTime === 0;
    // const whichClassname = enableHightlight
    //   ? classes.highlightTableCell
    //   : classes.tableCell;
    const whichClassname = classes.tableCell;
    return (
      <TableRow key={i}>
        <TableCell className={whichClassname} component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell className={whichClassname} align="center">
          {row.wTime}
        </TableCell>
        <TableCell className={whichClassname} align="center">
          {row.status}
        </TableCell>
        <TableCell className={whichClassname} align="center">
          {row.regLocation}
        </TableCell>
      </TableRow>
    );
  });

  console.log(rows.length);

  return (
    <Wrapper theme={theme}>
      <ThemeProvider theme={tableTheme}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>{headers}</TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </Wrapper>
  );
}

export default React.memo(DataTable);
