import React from "react";
import ReactDOM from "react-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const validHosts = [
  "pex.hcahealthcare.cloud",
  "pex-prod-afd.azurefd.net",
  "patient-reg-tracker-web.azurewebsites.net",
];
const curHost = window.location.hostname;
const trackHost = validHosts.some((vh) => vh === curHost);

if (trackHost) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: "bb79aba2-bff7-49ac-b983-60d2b139df66",
      loggingLevelTelemetry: 2, // sends warnings and errors
      autoTrackPageVisitTime: true, //sends time spent on each page
      enableAutoRouteTracking: true, //tracks route changes in SPA
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
