/* eslint-disable sort-keys */
export const borderRadius = "4px";

export const color = {
  // Backgrounds
  backgroundLight: "#FFFFFF",
  backgroundPrimary: "#D9D9D6",
  backgroundSecondary: "#F5F5F5",
  backgroundTertiary: "#3b4353",
  backgroundQuaternary: "#282d38",
  backgroundNav: "#282d37",

  // Borders
  borderPrimary: "#575961",
  borderSecondary: "#f0f0f0",
  borderTertiary: "#d8d8d8",

  // Primary
  black: "#000",
  primary: "#3e91ff",
  primaryGradient: "#31b1ff",
  secondary: "#9acbea",
  white: "#fff",
  offWhite: "#f7f7f7",
  highlight: "rgba(61, 145, 255, 0.2)",
  inactive: "#485165",

  // Shadows
  shadowDropdown: "0 10px 15px #21242c",
  shadowLight: "0 10px 15px rgba(33, 36, 44, 0.3)",

  // Graph
  axisPrimary: "#fff",
  axisSecondary: "#434954",

  // Status
  statusError: "#f1524b",
  statusSuccess: "#26b86f",
  statusWarning: "#f5ae4b",

  // Text
  textExtraDark: "#21242c",
  textDark: "#575961",
  textVeryDark: "#333",
  textLight: "#94969f",
  textMedium: "#656c7c",
  textPrimary: "#1F2532",
  textSecondary: "#d8d8d8",
  textTertiary: "#4d4f56",
};

export const deviceWidth = {
  mobilePhone: "450px",
  largePhone: "600px",
  tablet: "800px",
  desktop: "1000px",
  largeDesktop: "1800px",
};

export const duration = {
  short: "160ms",
  medium: "240ms",
  long: "500ms",
};

export const font = {
  primary: "'Gotham', 'Arial', sans-serif",
};

export const fontWeight = {
  black: "900",
  bold: "700",
  semibold: "600",
  regular: "400",
  light: "300",
  extralight: "200",
};

export const gradient = {
  primary: "linear-gradient(to bottom, #52b7ff, #286bff)",
};

export const media = {
  largePhone: `(max-width: ${deviceWidth.largePhone})`,
  mobilePhone: `(max-width: ${deviceWidth.mobilePhone})`,
  tablet: `(max-width: ${deviceWidth.tablet})`,
  desktop: `(max-width: ${deviceWidth.desktop})`,
  largeDesktop: `(min-width: ${deviceWidth.largeDesktop})`,
};
/* eslint-enable sort-keys */

export default {
  borderRadius,
  color,
  deviceWidth,
  duration,
  font,
  fontWeight,
  gradient,
  media,
};
