import React from "react";
import styled from "styled-components";

import theme from "../../styles/theme";
const MHO_Logo_Horizontal = require("../../styles/icon/Desktop/MHO_Logo_Horizontal@2x.png");

const Wrapper = styled.div`
  align-items: center;
  box-shadow: 0 4px 10px 0 rgba(31, 37, 50, 0.2);
  background: ${(p) => p.theme.color.backgroundLight};
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 1024px;
  position: relative;
  margin: 0 auto;
  @media ${(p) => p.theme.media.mobilePhone} {
    justify-content: center;
    width: 100%;
  }
  @media ${(p) => p.theme.media.tablet} {
    padding: 0;
  }
`;

const Img = styled.img`
  margin: 12px 40px;
`;

function Nav() {
  return (
    <Wrapper theme={theme} aria-label="nav bar" role="Navigation">
      {/* <Logo height={20} /> */}
      <Img
        height={26}
        width={200}
        src={MHO_Logo_Horizontal}
        aria-label="My Health One Logo"
        alt="My Health One Logo"
      />
    </Wrapper>
  );
}

export default Nav;
