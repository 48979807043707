import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import get from "lodash.get";

const Wrapper = styled.div`
  align-items: center;
  background: ${(p) => p.theme.color.backgroundLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 72px;
  width: 1024px;
  margin: 0 auto;
  @media ${(p) => p.theme.media.mobilePhone} {
    text-align: center;
    width: 100%;
    height: 104px;
  }
`;

const FaTitle = styled.div`
  color: ${(p) => p.theme.color.textPrimary};
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  height: 32px;
  overflow-wrap: "break-word";
`;

function Banner({ globalState }) {
  const facility = get(globalState, "header.facilityName");
  return (
    <Wrapper theme={theme} role="banner" aria-label="welcome message">
      <FaTitle theme={theme} aria-label="Facility Name">
        Welcome to {facility ? facility.trim() : "a HCA Healthcare facility"}
      </FaTitle>
    </Wrapper>
  );
}

export default Banner;
